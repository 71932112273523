import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'
import AmazonDetailListing from '../components/AmazonListing'

const Store = () => (
  <Layout>
    <SEO customTitle="Store" />
    <CoolHeader>The Journals</CoolHeader>
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
      <AmazonDetailListing ASIN={`1707052166`} description={'01: Fernweh'} />
      <AmazonDetailListing
        ASIN={`1659402948`}
        description={'02: Put Into Words My Love'}
      />
    </div>
    <hr />

    <CoolHeader>Pomme Gear</CoolHeader>
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
      <AmazonDetailListing
        ASIN={`B07PKKB5B3`}
        // description={
        //   <p>{`Join the growing Pomme Community in style.`}</p>
        // }
        description={`Official Pomme T-Shirt`}
      />
      <AmazonDetailListing
        ASIN={`B07PH8PY2C`}
        // description={
        //   <p>{`Join the growing Pomme Community in style.`}</p>
        // }
        description={`Official Pomme T-Shirt`}
      />
    </div>
  </Layout>
)

export default Store
